@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

* {
    scroll-behavior: smooth;
}

/* Custom scrollbar */
::-webkit-scrollbar {
    width: 10px; /* Set the width of the scrollbar */
}

/* Track (background) color */
::-webkit-scrollbar-track {
    background: #ccc;
}

/* Handle (thumb) color */
::-webkit-scrollbar-thumb {
    background: #4292a7;
    border-radius: 5px;
}

.scroll-box-graphical-hours {
    scroll-behavior: smooth;
}
.scroll-box-graphical-hours::-webkit-scrollbar {
    display: none;
}
